














import { defineComponent, PropType } from "@vue/composition-api"

import { useI18n } from "@/_i18n"

import DownloadLink from "./DownloadLink.vue"

export default defineComponent({
  name: "DownloadRow",

  components: { DownloadLink },

  props: {
    value: {
      type: [String, Number],
      default: "-",
    },
    link: {
      type: Object as PropType<Location>,
      default: undefined,
    },
  },

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
