

















import { computed, defineComponent, h } from "@vue/composition-api"

import { useI18n } from "@/_i18n"

import {
  getStatistics,
  Statistic,
  StatisticRequest,
  StatisticResponse,
} from "@/_service/client"

import { useApiCall } from "@/_use/apiCall"

import { FetchOperation } from "@/_service/http"
import { operations } from "@/_service/client"

import { getUser } from "@/_store/user"

import Loader from "@/component/Loader.vue"
import KeyValueBlock from "@/component/KeyValueBlock.vue"

import { useDownloadLink } from "@/router"

import DownloadRow from "./DownloadRow.vue"

export default defineComponent({
  name: "StatisticCard",
  props: {
    clientName: {
      type: String,
      default: "",
    },
    clientId: {
      type: Number,
      default: undefined,
    },
  },
  components: { Loader, KeyValueBlock },

  setup({ clientId }) {
    const { t } = useI18n(
      require.context("../", true, /Dashboard\.[\w-]+\.(json|ya?ml)$/i)
    )
    const { isLoading, result, onSubmit, hasErrors, errorMessage } = useApiCall<
      StatisticRequest,
      StatisticResponse
    >(getStatistics, t, { clientId })

    const isOwnCard = computed(() => {
      const user = getUser()
      return user?.clientId === clientId
    })

    const opMap: Record<keyof Statistic, FetchOperation> = {
      domains: operations.downloadDomains,
      mailboxes: operations.downloadMailboxes,
      aliases: operations.downloadAliases,
      clientId: operations.downloadAliases,
    }

    const statsData = computed(() => {
      const stats = result.value?.data as Statistic

      return (["domains", "mailboxes", "aliases"] as Array<
        keyof Statistic
      >).map((key) => ({
        key: t(`statistics.${key}`),
        value: stats?.[key],
        slotValue: h(DownloadRow, {
          props: {
            value: stats?.[key],
            link: useDownloadLink(
              opMap[key].url,
              isOwnCard.value ? {} : { clientId: `${clientId}` }
            ).value,
          },
        }),
      }))
    })

    const isLoaded = computed(() => {
      return !!result.value?.data
    })

    const onIsVisible = (_: unknown, __: unknown, isIntersecting: boolean) => {
      if (isIntersecting && !isLoading.value && !isLoaded.value) {
        onSubmit()
      }
    }

    return {
      isLoading,
      hasErrors,
      errorMessage,
      statsData,
      onIsVisible,
      isLoaded,
    }
  },
})
