




















import { Route } from "vue-router"
import { Location } from "vue-router/types/router"

import { computed, defineComponent, PropType, ref } from "@vue/composition-api"

import { downloadPropsFromRoute } from "@/router"

import Download from "@/view/Download.vue"

export default defineComponent({
  name: "DownloadLink",

  components: { Download },

  props: {
    to: {
      type: Object as PropType<Location>,
      required: true,
    },
  },

  setup(props, ctx) {
    const attrs = computed(() => ctx.attrs)
    const listeners = computed(() => ctx.listeners)
    const isDownloading = ref<boolean>()

    // the `to` property of `router-link` can not be used directly
    // because in this case the default link behaviour can not be cancelled
    // through JavaScript, the `href` prop need to be used instead
    // https://github.com/vuejs/vue-router/issues/916
    const href = computed(() => {
      const { href } = ctx.root.$router.resolve(props.to)

      return href
    })

    const downloadProps = computed(() => {
      return downloadPropsFromRoute(props.to as Route)
    })

    const startDownload = () => {
      isDownloading.value = true
    }

    const completeDownload = () => {
      isDownloading.value = false
    }

    return {
      attrs,
      completeDownload,
      downloadProps,
      href,
      isDownloading,
      listeners,
      startDownload,
    }
  },
})
