



























import { defineComponent, PropType } from "@vue/composition-api"
import { VNode } from "vue"

type NameValue = {
  key: string | number
  value?: string
  slotValue?: VNode | undefined
}

const VNodeRenderer = defineComponent({
  name: "NodeRenderer",
  props: {
    node: {
      type: Object as PropType<VNode | undefined>,
      default: undefined,
    },
  },
  setup(props) {
    return () => props.node || ""
  },
})

export default defineComponent({
  name: "KeyValueBlock",
  components: {
    VNodeRenderer,
  },
  props: {
    values: {
      type: Array as PropType<NameValue[]>,
      required: true,
    },
  },
})
