



















import { Dictionary } from "vue-router/types/router"

import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
} from "@vue/composition-api"

import { getJwt } from "@/_store/user"
import { useLink } from "@/_use/apiCall"

export default defineComponent({
  name: "Download",

  props: {
    path: {
      type: String,
      required: true,
    },
    query: {
      type: Object as PropType<Dictionary<string>>,
      default: () => ({}),
    },
    inline: {
      type: Boolean,
    },
  },

  setup(props, ctx) {
    const form = ref<HTMLFormElement | null>(null)
    const action = useLink(props.path)
    const jwt = computed(getJwt)

    // convert query parameters to objects e.g.
    // `{clientId: "5"}` => `{name: "clientId", value: "5"}`
    const params = Object.keys(props.query).map((it) => ({
      name: it,
      value: props.query[it],
    }))

    /**
     * automatically submit the form and return to previous page
     */
    onMounted(() => {
      form.value?.submit()

      if (props.inline) {
        ctx.emit("done")
      } else {
        // return to previous page after data submission
        ctx.root.$router.back()
      }
    })

    return {
      action,
      form,
      jwt,
      params,
    }
  },
})
