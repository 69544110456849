import { ref, Ref, computed, reactive, watch } from "@vue/composition-api"

import { useI18n } from "@/_i18n"

import { Client, ClientRequest, getClient } from "@/_service/client"

import { useClients as storeUseClients } from "@/_store/client"

import { useApiCall } from "@/_use/apiCall"

export const useClients = storeUseClients

/**
 * Get the own client if not in store load it
 *
 * @returns isLoading: if the client is loading
 *          client: the own client object
 */
export const useClient = (
  id: number
): {
  /** the client object from backend */
  client: Ref<Client | undefined>
  /**
   *  reactive property, which on change enforce loading of client with specified ID
   */
  clientId: Ref<number>
  /** error message from response */
  errorMessage: Ref<string | undefined>
  /** is error occur on fetching the client? */
  hasErrors: Ref<boolean>
  /** loading indicator (request is currently executed) */
  isLoading: Ref<boolean>
} => {
  const { t } = useI18n()

  const clientId = ref<number>(id)
  const getClientQuery = reactive<ClientRequest>({ clientId: clientId.value })

  const {
    errorMessage,
    hasErrors,
    isLoading,
    onSubmit: refresh,
    result,
  } = useApiCall<ClientRequest, Client>(getClient, t, getClientQuery)

  watch(
    clientId,
    async () => {
      getClientQuery.clientId = clientId.value
      refresh()
    },
    { immediate: true }
  )

  const client = computed(() => {
    return result.value?.data as Client | undefined
  })

  return {
    client,
    clientId,
    errorMessage,
    hasErrors,
    isLoading,
  }
}
