












import { computed, defineComponent, Ref, ref } from "@vue/composition-api"

import { AdminConstraint } from "@/_permission"
import { Client } from "@/_service/client"
import { getUser } from "@/_store/user"
import { useClient, useClients } from "@/_use/client"

import Loader from "@/component/Loader.vue"
import StatisticCard from "./component/StatisticCard.vue"

interface UseClients {
  isLoading: Ref<boolean>
  cached: Ref<Array<Client> | undefined>
}

function useOwnClients(): UseClients {
  let useClients: UseClients = {
    isLoading: ref(false),
    cached: ref(undefined),
  }

  const currentUser = getUser()

  if (currentUser?.clientId) {
    const { isLoading, client } = useClient(currentUser.clientId)
    const cached = computed(() => {
      return client.value ? [client.value] : undefined
    })
    useClients = {
      isLoading,
      cached,
    }
  }
  return useClients
}

export default defineComponent({
  name: "Dashboard",
  components: { Loader, StatisticCard },

  setup() {
    const isAdmin = AdminConstraint.isSatisfied()

    const { isLoading, cached: clients } = isAdmin
      ? useClients()
      : useOwnClients()

    return {
      isLoading,
      clients,
    }
  },
})
